export const arrayTypeServices = [
    {
        nameTypeService: "Хромирование кованных деталей",
        imageTypeService : './images/Service/kov-tools.jpg',
        href: "#",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
    {
        nameTypeService: "Хромирование рекламной продукции",
        imageTypeService : './images/Service/ads.jpg',
        href: "#",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
    {
        nameTypeService: "Хромирование мебели",
        imageTypeService : './images/Service/table.jpg',
        href: "#",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
    {
        nameTypeService: "Хромирование посуды",
        imageTypeService : './images/Service/kitchen.jpeg',
        href: "#",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
    {
        nameTypeService: "Хромирование предметов интерьера",
        imageTypeService : './images/Service/room.webp',
        href: "#",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
    {
        nameTypeService: "Создание сайта",
        imageTypeService : './images/Service/web.jpg',
        href: "https://tlgg.ru/@matyha_62",
        arraySevices: [
            {
                nameService: "",
                description: "",
                media: {
                    images: [],
                    videos: [],
                }
            }
        ],
    },
]