export const arrayPortfolioImages = [
    'https://sun9-40.userapi.com/impg/k0Oxes1O96gyckx_H5pD4Iz1jZYbm8tZuChZ6A/IfZbNs2PIO0.jpg?size=1407x2160&quality=95&sign=ce45763e1a8c9a5a569b5da12e8d66a9&type=album',
    'https://sun9-58.userapi.com/impg/ageYCV5jjBInWZlkd_66ebNSE7LBh9JaUA8w5g/b7KIOAgRUMM.jpg?size=1122x1160&quality=95&sign=553d4beb376fdc4013f8d652684b17dd&type=album',
    'https://sun9-25.userapi.com/impg/xjWYJ0u16zrFH9T9Ij0Ow2FyfM8YeiSyMVy3XQ/wuqoZo7jGTA.jpg?size=2560x1920&quality=95&sign=a41bec5fe54f59052e6b4cd5195e3f66&type=album',
    'https://sun9-61.userapi.com/impg/MXhKG7ZibkkjezRp7mcKQuSnQKe21h-t5w_B1Q/p7oaEnq2KJk.jpg?size=1216x2160&quality=95&sign=5c9a9d0e90f8301ef63c7a0ea1cd658a&type=album',
    'https://sun9-48.userapi.com/impg/TWpL5Hzl7VU7AWy0EhxrI8P9R52teENC7Aisqg/sbt0KwR8390.jpg?size=1216x2160&quality=95&sign=26c5d5503ce4ccfe6352e46b569b4539&type=album',
    'https://sun9-74.userapi.com/impg/wq9N3rRDdbRiC10Id2dhu1M-JITZTpx72050FA/iKRtY-rlOCI.jpg?size=1041x1444&quality=95&sign=f493249a77678326de16c5d85673a71b&type=album',
    'https://sun9-42.userapi.com/impg/PWMlg5Isk3JjNvXzCpE6CAdJ8l3N2SyjM1ZlYQ/JY_j2hN9yC4.jpg?size=768x1024&quality=95&sign=c15f9a277c8a53ff0909d65ac9e12e64&type=album',
    'https://sun9-13.userapi.com/impg/IbuFbMKmpFm42U-Dp5IW3bhcbnGaLFufecW0Hg/bKyxa3Vu5xY.jpg?size=2560x1920&quality=95&sign=4d8fb797653a73c923083e8f27dcb301&type=album',
    'https://sun9-16.userapi.com/impg/Nqqg4Mrj9p8Fk4u9cIELrIY2BXae7KDOZJdAeA/oFg2umNOv38.jpg?size=2560x1707&quality=95&sign=7171c2e5cfdeaf34060b2cc4ac6f0eac&type=album',
    'https://sun9-73.userapi.com/impg/QC9lmGsBESXUYGTzfF0bNYE7tBH95cukyhS71Q/zCSFpDzc4QQ.jpg?size=683x705&quality=95&sign=59bebef69929cccf5181c6fa17b39a54&type=album',
    'https://sun9-58.userapi.com/impg/iRHu7QZfPZpTQ_XgsjMIpotw9VjEHCaJ9KI9hw/gZdh2HpCOQQ.jpg?size=2560x1920&quality=95&sign=56ac9ac04ead495ca440a3516cd06e71&type=album',
    'https://sun9-7.userapi.com/impg/TcWCSUxeoml1zUMcqmpaweE9GjGYiDZVUAeA1Q/CvxA9KXHYWU.jpg?size=2560x1440&quality=95&sign=2d88de731bdebfc806bc3fb29071eadb&type=album',
    'https://sun9-49.userapi.com/impg/1n9glSJIKCm4eoNJKwvsLxMESdJWoDO-SsqQGw/ndzLH6_J6pw.jpg?size=2560x1440&quality=95&sign=a46ec86be0184888fdf98a72d1116c59&type=album',
    'https://sun9-47.userapi.com/impg/SdKKBEDELduHOZUItjiSNIsyXptarNM86YtYJw/W3N9KjcjME8.jpg?size=2560x1440&quality=95&sign=5a693caef2f5c3bacfbe636cbff53d17&type=album',
    'https://sun9-43.userapi.com/impg/UM_cWRQcZ2Pu62uFatfVJB_vAWK9TJGM_Y4mFw/trTG1bKaVbI.jpg?size=2560x1920&quality=95&sign=986b27e98c7dbe4b189a08430cfd90e8&type=album',
    'https://sun9-35.userapi.com/impg/5ld7Quz7amPBYDUBfCxevO16kGDjliB41YfJ1w/VgukeR3NMKM.jpg?size=2560x1440&quality=95&sign=6b882d9d4483998cb9749e25f3170654&type=album',
]